@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~video-react/dist/video-react.css";

.text {
  background: rgb(60, 140, 180);
}

.video {
  background: rgb(82, 185, 75);
}

.audio {
  background: rgb(74, 129, 217);
}
